import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Button from "../../global/Button";
import { makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { getItemFromStore, showToast } from "../../utils";
import {
  CalendarIcon,
  DateMinus,
  DatePlus,
  LeftArrowStickIcon,
  LocationGreyIcon,
  RightArrowIcon,
  SpClockIcon,
} from "../../utils/Icons/svgs";
import EventDateCard from "./Popups/EventDateCard";
import WaitlistPopup from "./Popups/WaitlistPopup";
import { LISTING_TYPE } from "../../types";
import EventArchivedCard from "./Popups/EventArchivedCard";
import DateCounter from "./DateCounter";

const EventDateBox = (props) => {
  const navigate = useNavigate();
  const {
    selDates,
    detailsData,
    setIntitalBookEvents,
    initialBookEvents,
    setParentPackageCounter,
  } = props;
  const [showWaitlistPopup, setWaitlistPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState<any>("upcoming");
  const [checkedItems, setCheckedItems] = useState([]);
  const [packageCounter, setPackageCounter] = useState(1);
  const { id } = useParams();
  const event_id = id;
  let bookingarr = [];

  const handleBooking = () => {
    if (bookingarr.length < 1) {
      showToast("Please select at least one event.", "error");
      return;
    }

    setIntitalBookEvents(bookingarr);
    props.handleNextStep();
  };

  ////PACKAGE BOOKING

  const handlePackageBooking = () => {
    setParentPackageCounter(packageCounter);
    props.handleNextStep();
  };

  const handleModal = () => {
    props.onHide();
    props.setShowScheduleModal(true);
    let datesArray = [];

    for (let i = 0; i < props.detailsData?.events?.length; i++) {
      for (let k = 0; k < bookingarr?.length; k++) {
        if (props.detailsData?.events?.[i]?.id === bookingarr[k].event) {
          datesArray.push(props.detailsData?.events?.[i]?.date);
        }
      }
    }

    console.log(bookingarr);
    console.log(datesArray);
    localStorage.setItem("user-" + event_id, JSON.stringify(bookingarr));
    props.setSelDates(datesArray);
  };

  const handleWaitlist = (eventId) => {
    const listing_type =
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
        ? LISTING_TYPE.S
        : detailsData?.listingType === LISTING_TYPE.M
        ? LISTING_TYPE.M
        : "";
    let waitlistData: any = {};
    if (listing_type === LISTING_TYPE.M) {
      waitlistData.listing_type = listing_type;
      waitlistData.listing = eventId;
    } else if (listing_type === LISTING_TYPE.S) {
      waitlistData.listing_type = listing_type;
      waitlistData.event = eventId;
    }

    setIsLoading(true);
    makePostCall({ url: endpoints.listings_waitlist, apiPayload: waitlistData })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoading(false);
          setWaitlistPopup(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  function isDisabled() {
    if (detailsData?.listingType !== LISTING_TYPE.S) {
      if (detailsData?.listingType !== LISTING_TYPE.SPECIAL) {
        if (
          detailsData?.teamSlotsAvailable === 0 ||
          detailsData?.teamSlotsAvailable === null
        ) {
          return true;
        }
      }
    }
    return false;
  }

  const handleMinusCounter = () => {
    if (packageCounter > 1) {
      setPackageCounter((prev) => prev - 1);
    }
  };
  const handlePlusCounter = () => {
    if (packageCounter < detailsData?.teamSlotsAvailable) {
      setPackageCounter((prev) => prev + 1);
    }
  };

  const showNoArchiveData = props?.eventsArray
    ?.slice(0, 8)
    ?.filter((item, i) => {
      return moment(item?.date).diff(moment(), "minutes") < 0;
    });

  const fetchVisibleEventsArray = (props) => {
    let visible_array = [];
    let k = 0;
    props?.eventsArray?.map(
      (item, i) => {
        if (moment(item?.date).diff(moment(), "minutes") > 0 && k < 8) {
          visible_array.push(
            <EventDateCard
              event_id={event_id}
              item={item}
              i={i}
              maxParticipants={props.maxParticipants}
              key={i}
              bookingarr={bookingarr}
              isSingleEvent={props?.eventsArray.length === 1}
              setSelDates={props.setSelDates}
              selDates={selDates}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              initialBookEvents={initialBookEvents}
              clickWaitlist={(eventId) => handleWaitlist(eventId)}
            />
          );
          k++;
        }
      }
      // moment(item?.date).diff(moment(), "minutes") > 0 ? (
      //   visible_array.push(<EventDateCard
      //     item={item}
      //     i={i}
      //     maxParticipants={props.maxParticipants}
      //     key={i}
      //     bookingarr={bookingarr}
      //     isSingleEvent={props?.eventsArray.length === 1}
      //     setSelDates={props.setSelDates}
      //     selDates={selDates}
      //     checkedItems={checkedItems}
      //     setCheckedItems={setCheckedItems}
      //     initialBookEvents={initialBookEvents}
      //     clickWaitlist={(eventId) =>
      //       handleWaitlist(eventId)
      //     }
      //   />)
      // )
      //   :
      //   null
    );
    return visible_array;
  };
  return (
    <>
      {(detailsData?.listingType === LISTING_TYPE.S ||
        detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
        <div className="emodal_wrapper mx-auto" style={{ maxWidth: "720px" }}>
          <div className="edates_wrapper">
            <div className="e_all_dates_container res-container row gx-0 flex-gap-24">
              <div className="active-inactive-box active-inactive-box-res">
                <div
                  className="item cursor-pointer"
                  onClick={() => setActive("upcoming")}
                >
                  <div className={active === "upcoming" ? "active" : ""}>
                    Upcoming
                  </div>
                </div>
                <div
                  className="item cursor-pointer"
                  onClick={() => setActive("archived")}
                >
                  <div className={active === "archived" ? "active" : ""}>
                    Archived
                  </div>
                </div>
              </div>

              {active === "upcoming" ? (
                <>
                  {props.second
                    ? props?.selectedEvents?.map((item, i) =>
                        moment(item?.date).diff(moment(), "minutes") > 0 ? (
                          <EventDateCard
                            event_id={event_id}
                            item={item}
                            isSingleEvent={props?.eventsArray.length === 1}
                            i={i}
                            maxParticipants={props.maxParticipants}
                            key={i}
                            bookingarr={bookingarr}
                            second={true}
                            clickWaitlist={(eventId) => handleWaitlist(eventId)}
                          />
                        ) : null
                      )
                    : fetchVisibleEventsArray(props)}
                </>
              ) : (
                <>
                  {props?.eventsArray
                    ?.slice(0, 8)
                    ?.map(
                      (item, i) =>
                        moment(item?.date).diff(moment(), "minutes") < 0 && (
                          <EventArchivedCard
                            item={item}
                            i={i}
                            maxParticipants={props.maxParticipants}
                            isSingleEvent={props?.eventsArray.length === 1}
                            key={i}
                            bookingarr={bookingarr}
                            setSelDates={props.setSelDates}
                            selDates={selDates}
                            clickWaitlist={(eventId) => handleWaitlist(eventId)}
                          />
                        )
                    )}
                  {showNoArchiveData.length === 0 && (
                    <div style={{ margin: "16px auto", textAlign: "center" }}>
                      <img alt="" src="/static/archived.svg" />
                      <div
                        style={{
                          marginTop: "16px",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "#8F939A",
                        }}
                      >
                        No archived date to show
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {props?.eventsArray?.length > 8 && active === "upcoming" ? (
              <div
                className="schedule_event_dates schedule_event_dates_res my-6 cursor-pointer"
                onClick={() => {
                  handleModal();
                }}
              >
                <div className="d-flex align-items-center ver-p4 px-2 gap-1">
                  <span>
                    <CalendarIcon />
                  </span>
                  <p className="mb-0 sch_txt">Schedule Sessions Dates</p>
                </div>
                <div>
                  <RightArrowIcon />
                </div>
              </div>
            ) : (
              ""
            )}
            {active === "upcoming" ? (
              <div className="btn_footer_listing_booking">
                <Button
                  style={{ width: "350px" }}
                  btnTxt={props?.isEventFull ? "Go Back" : "Save & Continue"}
                  className={"backtologin save-btn-b"}
                  isLoading={isLoading}
                  disabled={
                    detailsData?.createdBy?.id ===
                    getItemFromStore("userProfile")?.id
                  }
                  onClick={() => {
                    if (props?.isEventFull) {
                      navigate(-1);
                    } else {
                      handleBooking();
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}

      {detailsData?.listingType === LISTING_TYPE.M && (
        <div className="session_p_modal position-relative p-3  px-md-0">
          <div className="sp_txt mt-2 mb-4">
            <p className="sp_title mb-0">
              Please select the number of attendees*
            </p>
            {/* <p className="sp_subtitle mb-0">
              Please check all the available events
            </p> */}
          </div>
          <div className="s_p_counter mb-4">
            <span
              className="d-flex align-items-center cursor-pointer"
              style={{
                borderRadius: "100%",
                backgroundColor: "rgba(238, 120, 48, 0.4)",
              }}
              onClick={() => handleMinusCounter()}
            >
              <DateMinus />
            </span>
            <span>{packageCounter}</span>
            <div
              className="d-flex align-items-center cursor-pointer"
              style={{
                borderRadius: "100%",
                backgroundColor: "rgba(238, 120, 48, 0.4)",
              }}
              onClick={() => handlePlusCounter()}
            >
              <DatePlus />
            </div>
          </div>
          <hr style={{ color: "#d3d3d3" }} />
          <div
            style={{
              textAlign: "left",
              marginBottom: "16px",
              fontSize: "16px",
              fontWeight: "500",
              fontFamily: "Poppins",
            }}
          >
            Sessions Included ( {detailsData?.events?.length} Sessions)
          </div>
          <div className="mx-auto row gap-4">
            {detailsData?.events?.map((item, i) => {
              return (
                <div className="sp_date_container_new mx-auto col-12 col-md-5">
                  <div className="row">
                    <div
                      className={
                        detailsData?.listingEventType !== "V"
                          ? "sp_only_date col-3 d-sm-block d-none"
                          : "sp_only_date col-3 sp_info_new"
                      }
                    >
                      <p className="sp_date_month mb-0">
                        {moment(item?.date).format("MMMM")}
                      </p>
                      <p className="sp_date_date mb-0">
                        {moment(item?.date).date()}
                      </p>
                      <p className="sp_date_day mb-0">
                        {moment(item?.date).format("ddd")}
                      </p>
                    </div>
                    {/* <hr /> */}
                    <div
                      className={
                        detailsData?.listingEventType !== "V"
                          ? "sp_info  col-8"
                          : "sp_info  col-8 sp_info_new"
                      }
                    >
                      <div className="sp_title">{item.title} </div>
                      {detailsData?.listingEventType !== "V" ? (
                        <div className="sp_loc">
                          <div className="d-flex">
                            <LocationGreyIcon />
                          </div>
                          <div className="loc loc-res">
                            {item?.address?.address}
                          </div>
                          <div
                            className="sp_map d-sm-block d-none"
                            onClick={() => {
                              props.onHide();
                              props.setOpenMap(true);
                              props.setEventCenter(item?.address?.location);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            open maps
                          </div>
                        </div>
                      ) : null}

                      <div className="sp_time">
                        <span className="d-sm-none d-inline">
                          <img src="/static/Calendar22.svg" />{" "}
                          {moment(item?.date).format("DD MMM, YYYY")}
                        </span>
                        {"  "} <SpClockIcon />{" "}
                        {moment(item?.startTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                        -{" "}
                        {moment(item?.endTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="d-none d-sm-flex justify-content-center package_wrapper mt-4"
            style={{ left: "0", right: "0" }}
          >
            <Button
              btnTxt={`${isDisabled() ? "Booking Full" : "Save & Continue"}`}
              className={"backtologin "}
              style={{ width: "350px" }}
              isLoading={isLoading}
              onClick={() => {
                handlePackageBooking();
              }}
              disabled={isDisabled()}
            />
          </div>

          {isDisabled() && (
            <div className="d-none d-sm-flex justify-content-center package_wrapper mt-3">
              <Button
                btnTxt="Add to waitlist"
                className={"backtologin "}
                style={{ width: "350px" }}
                onClick={() => {
                  handleWaitlist(event_id);
                }}
              />
            </div>
          )}
        </div>
      )}

      {detailsData?.listingType === LISTING_TYPE.M && (
        <div
          className="emodal_wrapper mx-auto d-flex d-sm-none"
          style={{ maxWidth: "720px" }}
        >
          <div className="edates_wrapper">
            <div
              className="btn_footer_listing_booking"
              style={{ position: "fixed" }}
            >
              <Button
                btnTxt={`${isDisabled() ? "Booking Full" : "Save & Continue"}`}
                className={"backtologin "}
                style={{ width: "350px" }}
                isLoading={isLoading}
                onClick={() => {
                  handlePackageBooking();
                }}
                disabled={isDisabled()}
              />
              {isDisabled() && (
                <Button
                  btnTxt="Add to waitlist"
                  className={"backtologin "}
                  style={{ width: "350px" }}
                  onClick={() => {
                    handleWaitlist(event_id);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {detailsData?.listingType === LISTING_TYPE.C && (
        <div className="session_p_modal position-relative p-3 px-md-0 calc-height">
          <div
            className="d-none position-relative cursor-pointer my-2"
            onClick={() => props.onHide()}
          >
            <LeftArrowStickIcon />
          </div>

          <div className="sp_txt mb-4">
            <p className="sp_title mb-0">
              Please select the number of attendees*
            </p>
            {/* <p className="sp_subtitle mb-0">
              Please check all the available events
            </p> */}
          </div>
          <div className="s_p_counter mb-4">
            <span
              className="d-flex align-items-center cursor-pointer"
              style={{
                borderRadius: "100%",
                backgroundColor: "rgba(238, 120, 48, 0.4)",
              }}
              onClick={() => handleMinusCounter()}
            >
              <DateMinus />
            </span>
            <span>{packageCounter}</span>
            <div
              className="d-flex align-items-center cursor-pointer"
              style={{
                borderRadius: "100%",
                backgroundColor: "rgba(238, 120, 48, 0.4)",
              }}
              onClick={() => handlePlusCounter()}
            >
              <DatePlus />
            </div>
          </div>

          {isDisabled() && (
            <div className="d-flex justify-content-center package_wrapper mt-3">
              <Button
                btnTxt="Add to waitlist"
                className={"backtologin "}
                style={{ width: "350px" }}
                onClick={() => {
                  handleWaitlist(event_id);
                }}
              />
            </div>
          )}
          <div
            className="d-flex justify-content-center package_wrapper mt-4"
            style={{
              left: "0",
              right: "0",
              position: "absolute",
              bottom: "24px",
            }}
          >
            <Button
              btnTxt={`${isDisabled() ? "Booking Full" : "Save & Continue"}`}
              className={"backtologin "}
              style={{ width: "350px" }}
              isLoading={isLoading}
              onClick={() => {
                handlePackageBooking();
              }}
              disabled={isDisabled()}
            />
          </div>
        </div>
      )}

      <WaitlistPopup
        show={showWaitlistPopup}
        onHide={() => {
          props.setRefresh((prev) => !prev);
          setWaitlistPopup(false);
        }}
      />
    </>
  );
};
export default EventDateBox;
