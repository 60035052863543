import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import endpoints from "../../utils/endpoints";
import axios, { AxiosProgressEvent } from "axios";
import {
  convertBytesToMB,
  formatBytes,
  getItemFromStore,
  removeLocalStorage,
  scrollToTop,
  setLocalStorage,
  showToast,
} from "../../utils";
import { makeDeleteCall, makeGetCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import GlobalHeader from "../Dashboard/GlobalHeader";

const DocumentUpload = () => {
  const navigate = useNavigate();
  const handleNext = () => {
    removeLocalStorage("userData");
    setLocalStorage("isProfileComplete", true);
    navigate(ROUTE_CONSTANTS.dashboard);
    scrollToTop();
  };

  const [uploadFiles, setUploadFiles] = useState<any>([]);
  const [oldFiles, setOldFiles] = useState<any>([]);
  const [callUploadData, setCallUploadData] = useState<boolean>(false);
  const [uploading, setUploading] = useState(false);

  const handleFileUpload = (e: any) => {
    const file = e.target.files;
    const fileSize = Number(convertBytesToMB(e.target.files[0].size));
    if (fileSize <= 50) {
      let images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        images.push(e.target.files[i]);
      }

      setUploadFiles([...uploadFiles, ...images]);
      setUploading(true);
      setTimeout(() => {
        for (let i = 0; i < e.target.files.length; i++) {
          const formData = new FormData();

          formData.append("document", file[i]);
          formData.append("document_name", file[i].name.replace(" ", "_"));
          formData.append("document_file_size", file[i].size);
          formData.append("document_file_type", "pdf");

          axios
            .post(
              endpoints.API_BASE_URL + endpoints.document_upload,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${getItemFromStore("access_token")}`,
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                  if (progressEvent && progressEvent.total) {
                    const progress = Math.round(
                      (progressEvent.loaded / progressEvent.total) * 100
                    );
                    let content: any = document.getElementById(`docu_${i}`);
                    let progressUpdate: any =
                      document.getElementById(`progress_${i}`) || "";
                    if (progress === 100) {
                      setUploading(false);
                    }
                    if (progressUpdate && content)
                      progressUpdate.style.maxWidth = progress + "%";
                    if (content?.innerHTML) {
                      content.innerHTML =
                        progress <= 100 ? `${progress} %` : "Done";
                    }
                  }
                },
              }
            )
            .then((res) => {
              if (i === e.target.files.length - 1) {
                setCallUploadData(true);
              }
              if (res.data.status.code !== 200) {
                showToast(res.data.status.message, "error");
              }
            })
            .catch((err) => {
              showToast(err, "error");
            });
        }
      }, 1500);
    } else {
      showToast("File should be less than 50MB.", "error");
    }
  };

  useEffect(() => {
    makeGetCall({ url: endpoints.trainer_docs })
      .then((res) => {
        setOldFiles(res.data);
        setUploadFiles([]);
        setCallUploadData(false);
      })
      .catch((err) => {
        setCallUploadData(false);
      });
  }, [callUploadData]);

  const deleteFile = (data: any) => {
    let apiPayload = {
      fileId: data.id,
    };
    makeDeleteCall({ url: endpoints.trainer_docs, apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(
            `${data.documentName} deleted successfully`,
            "success",
            "delete"
          );

          setCallUploadData(true);
        }
      })
      .catch((err) => showToast(err, "error"));
  };
  return (
    <div className="container-fluid p-0 margin-top-adjust">
      <div className="manage_profile  business_interest">
        <GlobalHeader />

        <div className="profile_box ">
          <div className="title ps-4 ps-sm-0">
            Upload & attach your qualifications?
            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="sub_title">
            Let your experience shine and connect with clients. (Upload max. 5)
          </div>
          <div className="progressbar">
            <span style={{ maxWidth: "100%" }}></span>
          </div>
          <div className="document-upload">
            <label
              htmlFor="file"
              className={"upload_doc_label"}
              style={{ cursor: uploadFiles.length ? "text" : "pointer" }}
              id="uploadLabel"
              onClick={() => {
                oldFiles.length >= 5 &&
                  showToast(STRING_CONSTANTS.upload_file_5, "error");

                uploading &&
                  showToast("File is uploading. Please wait a moment.", "error");
              }}
            >
              <h3>{STRING_CONSTANTS.upload_file_here}</h3>
              <p>{STRING_CONSTANTS.document_size_50}</p>
            </label>
            <input
              style={{ display: "none" }}
              type="file"
              name="upload_docs"
              disabled={uploadFiles.length || oldFiles.length >= 5}
              id="file"
              draggable
              onChange={handleFileUpload}
              onClick={(e: any) => (e.currentTarget.value = "")}
            />
            {uploading ? (
              <div
                style={{
                  margin: "16px 0px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#EE7830",
                }}
              >
                Document uploading please wait...
              </div>
            ) : null}
            {uploadFiles.length > 0 && (
              <ul className="file-cont">
                {uploadFiles.map((item: any, index: number) => {
                  return (
                    <>
                      <li className="file_preview" key="index">
                        <img
                          className="file_img"
                          src="/static/file.svg"
                          alt=""
                        />
                        <div className="name">
                          <p>
                            {item.name}{" "}
                            <span className="size">
                              {" "}
                              {formatBytes(item.size)}
                            </span>
                          </p>
                          <div className="progressbar">
                            <span id={`progress_${index}`}></span>
                          </div>
                        </div>
                        <div>
                          <img
                            className="file_check_icon"
                            src="/static/checked.svg"
                            alt=""
                          />
                          <p className="status" id={`docu_${index}`}></p>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            )}
            {oldFiles.length > 0 && (
              <ul className="file-cont">
                {oldFiles.map((item: any, index: number) => {
                  return (
                    <>
                      <li className="file_preview" key={index}>
                        <img
                          className="file_img"
                          src="/static/file.svg"
                          alt=""
                        />
                        <div className="name">
                          <p>
                            {item.documentName}
                            <span className="size">
                              {" "}
                              {formatBytes(item.documentFileSize)}
                            </span>
                          </p>
                          <div className="progressbar">
                            <span style={{ maxWidth: "100%" }}></span>
                          </div>
                        </div>
                        <div
                          className="delete_cont"
                          style={{ textAlign: "right" }}
                        >
                          <img
                            className="file_delete_icon"
                            src="/static/delete-red.svg"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteFile(item)}
                          />
                          <p className="status"> Done</p>
                        </div>
                      </li>
                    </>
                  );
                })}
              </ul>
            )}
          </div>
          {/* <CustomPreview /> */}

          <div className="continue_btn">
            <Button
              disabled={uploadFiles.length}
              btnTxt="Explore Fitness Mates"
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
