import moment from "moment/moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatAmount, getItemFromStore, showToast } from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import GoogleMapContainer from "../../global/GoogleMapContainer";
import { LeftArrowStickIcon } from "../../utils/Icons/svgs";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function BookingCard({
  id,
  chatRoom,
  date,
  slotsAvailable,
  totalSlots,
  earnings,
  eventBookings = [],
  earningDetailClick = () => {},
  bookersImage = [],
  address,
  listingEventType,
  virtualLink,
}) {
  const navigate = useNavigate();
  const [mapCenter, setMapCenter] = useState<any>();
  const [mapSidebar, setMapSidebar] = useState<any>();
  return (
    <>
      <div className="booking-card-wrap">
        <div className="date">
          {moment(date).format("dddd")},{moment(date).format("Do MMMM")}
        </div>
        <div className="img-content">
          {eventBookings.length ? (
            <div className="avatar-participants-container d-none d-md-flex">
              <div className="avatar-container">
                {bookersImage?.slice(0, 3)?.map((item) => {
                  return (
                    <Link to={`/our-mates/${item.id}`}>
                      {["mp4", "mov", "avi"].includes(
                        item?.profilePicture
                          ?.split(".")
                          .splice(-1)[0]
                          .toLowerCase()
                      ) ? (
                        <>
                          <div
                            key={item.profilePicture}
                            className="avatar-item"
                          >
                            <video
                              src={item?.profilePicture}
                              autoPlay
                              playsInline
                              loop
                              muted
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                objectFit: "cover",
                                verticalAlign: "middle",
                                borderRadius: "100%",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <source
                                src={
                                  item?.profilePicture ||
                                  "/static/dummyearningimg.svg"
                                }
                              />
                            </video>
                          </div>
                        </>
                      ) : (
                        <div key={item.profilePicture} className="avatar-item">
                          <img
                            src={
                              item.profilePicture ||
                              "/static/dummyearningimg.svg"
                            }
                            alt=""
                          />
                        </div>
                      )}
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : null}

          {eventBookings.length ? (
            <>
              <span className="spot">{slotsAvailable} Left out of </span>
              <span className="total_spot">{totalSlots} spots</span>{" "}
            </>
          ) : (
            <>
              <span className="total_spot">{slotsAvailable} Spots</span>{" "}
              <span className="spot">0 Booking </span>
            </>
          )}
        </div>

        {getItemFromStore("userProfile")?.userType !== "Guest" ? (
          <div className="open-maps-box-booking-detail-wrap">
            {listingEventType !== "V" ? (
              <div
                style={{ maxWidth: "190px" }}
                className="open-maps-box-booking-detail"
              >
                <img alt="" src="/static/location-black.svg" />
                <span className="t-sub-title-box">{address.address}</span>
                <div className="t-sub-title-box"></div>
                <span
                  className="map-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMapCenter({
                      lat: Number(address.latitude),
                      lng: Number(address.longitude),
                    });
                    setMapSidebar(true);
                  }}
                >
                  Open maps
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontFamily: "Poppins",
                }}
              >
                <img
                  src="/static/createListingIcon/linkGrey.svg"
                  alt=""
                  style={{ marginRight: "4px" }}
                />
                <span>
                  <a
                    target="_blank"
                    style={{
                      color: "#25272D",
                    }}
                    href={virtualLink || ""}
                    rel="noreferrer"
                  >
                    Meet Link
                  </a>

                  <span
                    style={{
                      color: "#0099FF",
                      cursor: "pointer",
                    }}
                  >
                    <CopyToClipboard
                      onCopy={() => showToast("copied to clipboard", "success")}
                      text={virtualLink}
                    >
                      <span>
                        <img
                          style={{
                            marginRight: "4px",
                            marginLeft: "6px",
                          }}
                          src="/static/createListingIcon/copyBlue.svg"
                          alt=""
                        />
                        <span>Copy</span>
                      </span>
                    </CopyToClipboard>
                  </span>
                </span>
              </div>
            )}
            <div
              className={earnings ? "earning-btn" : "zero-earning-btn"}
              onClick={earningDetailClick}
            >
              Earning: ${earnings ? formatAmount(earnings) || 0 : 0}
            </div>
          </div>
        ) : null}
        <div
          className={`${
            eventBookings.length ? "chat-btn" : "chat-btn-no-event"
          }`}
          onClick={() => {
            if (chatRoom) {
              navigate(`/chat/?chatId=${id}`);
            } else {
              navigate(`/chat/?sessionId=${id}`);
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M16 6C21.5228 6 26 10.4772 26 16C26 21.5228 21.5228 26 16 26C14.3817 26 12.8178 25.6146 11.4129 24.888L7.58704 25.9553C6.92212 26.141 6.23258 25.7525 6.04691 25.0876C5.98546 24.8676 5.98549 24.6349 6.04695 24.4151L7.11461 20.5922C6.38637 19.186 6 17.6203 6 16C6 10.4772 10.4772 6 16 6ZM16 7.5C11.3056 7.5 7.5 11.3056 7.5 16C7.5 17.4696 7.87277 18.8834 8.57303 20.1375L8.72368 20.4072L7.61096 24.3914L11.5976 23.2792L11.8671 23.4295C13.1201 24.1281 14.5322 24.5 16 24.5C20.6944 24.5 24.5 20.6944 24.5 16C24.5 11.3056 20.6944 7.5 16 7.5ZM12.75 17H17.2483C17.6625 17 17.9983 17.3358 17.9983 17.75C17.9983 18.1297 17.7161 18.4435 17.35 18.4932L17.2483 18.5H12.75C12.3358 18.5 12 18.1642 12 17.75C12 17.3703 12.2822 17.0565 12.6482 17.0068L12.75 17H17.2483H12.75ZM12.75 13.5L19.2545 13.5C19.6687 13.5 20.0045 13.8358 20.0045 14.25C20.0045 14.6297 19.7223 14.9435 19.3563 14.9932L19.2545 15H12.75C12.3358 15 12 14.6642 12 14.25C12 13.8703 12.2822 13.5565 12.6482 13.5068L12.75 13.5L19.2545 13.5L12.75 13.5Z"
              fill="#8F939A"
            />
          </svg>
        </div>
      </div>
      {mapSidebar ? (
        <div className="map-fixed-sidebar" style={{ zIndex: "1000" }}>
          <div className="map-sidebar-box ">
            <div className="map-heading d-none d-md-flex mt-4">
              <img
                src="/static/cross-black.svg"
                onClick={() => setMapSidebar(false)}
                className="close-btn"
                alt=""
              />
              <p>Maps</p>
            </div>
            <div className="sb-map-container sb-map-container-new">
              <div
                className="map-back-icon d-md-none"
                onClick={() => setMapSidebar(false)}
              >
                <LeftArrowStickIcon />
              </div>
              <GoogleMapContainer height="100%" center={mapCenter} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
