import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactSimplyCarousel from "react-simply-carousel";
import MediaContainer from "./MediaContainer";

type Props = {
  [key: string]: any;
};

const MediaCarousel = (props: Props) => {
  const { media = [] } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const moveSlide = (n) => {
    setCurrentSlide((prev) => prev + n);
  };
  return (
    <div className="slider" style={{height:"230px"}}>
      <div
        className="slides"
        style={{ transform: `translateX(${-currentSlide * 100}%)` }}
      >
        {media?.map((ph, index) => (
          <MediaContainer
            key={ph?.documentName + index}
            documentName={ph?.documentName}
            documentFileType={ph?.documentFileType}
            uploadDocumentUrl={ph?.uploadDocumentUrl}
            autoPlay={index === currentSlide}
            
          />
        ))}
      </div>

      <button
        className="btn_back"
        style={{top:"110px"}}
        onClick={(e) => {
          e.stopPropagation();
          moveSlide(-1);
        }}
        disabled={currentSlide === 0}
      >
        <img
          style={{ width: "16px", height: "16px" }}
          alt=""
          src="/static/chevron-black-left.svg"
        />
      </button>
      <button
        className="btn_next"
        style={{top:"110px"}}
        onClick={(e) => {
          e.stopPropagation();
          moveSlide(1);
        }}
        disabled={currentSlide === media?.length - 1}
      >
        <img
          style={{ width: "16px", height: "16px" }}
          alt=""
          src="/static/chevron-black-right.svg"
        />
      </button>
      <div className="dots_container">
        {media?.map((_, index) => (
          <div
            className={`${
              currentSlide === index ? "dots_active" : ""
            } dots_item`}
            key={"dots" + index}
          />
        ))}
      </div>
    </div>
  );
};

export default MediaCarousel;
